import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f0059b92 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _3d53b58f = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _54ce40e8 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _6194b64b = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _74616c34 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _7255e45c = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _31739e6b = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _1c278bd3 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _ccd9edb0 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _c615cf1c = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _37b078e8 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _bf580652 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _6345ef94 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _7b4e8064 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _00d56818 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _6cf154f3 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _9779e286 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _3e7d85b3 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _435f3bfd = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _71c2cece = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _f0059b92,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _3d53b58f,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _54ce40e8,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _6194b64b,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _74616c34,
    name: "receipt"
  }, {
    path: "/search",
    component: _7255e45c,
    name: "search"
  }, {
    path: "/search-booking",
    component: _31739e6b,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _1c278bd3,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _ccd9edb0,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _c615cf1c,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _37b078e8,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _bf580652,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _6345ef94,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _7b4e8064,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _00d56818,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _6cf154f3,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _9779e286,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _3e7d85b3,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _435f3bfd,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _71c2cece,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
